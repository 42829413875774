import React from 'react'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Logo from './Logo'

export default () => (
  <header className="bg-transparent
  ">
    {/* <Container> */}
    <Navbar collapseOnSelect expand="md" variant="dark" bg="primary">
  <Navbar.Brand href="/"><Logo /></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <NavDropdown title="Pseudo Jeux vidéos" id="collasible-nav-dropdown">
        <NavDropdown.Item href="/pseudo-fortnite/">Fortnite</NavDropdown.Item>
        <NavDropdown.Item href="/pseudo-minecraft/">Minecraft</NavDropdown.Item>
        <NavDropdown.Item href="/pseudo-runescape/">RuneScape</NavDropdown.Item>
        <NavDropdown.Item href="/pseudo-world-of-warcraft/">World Of Warcraft</NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title="Pseudo Réseaux Sociaux" id="collasible-nav-dropdown">
        <NavDropdown.Item href="/pseudo-instagram/">Instagram</NavDropdown.Item>
        <NavDropdown.Item href="/pseudo-discord/">Discord</NavDropdown.Item>
      </NavDropdown>
    </Nav>
    <Nav>
      <Nav.Link href="/contact/">Contact</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
    {/* </Container> */}
  </header>
  
)
