import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Meta from './Meta'
//import Nav from './Nav'
//import Footer from './Footer'
import MyFooter from './MyFooter'
import MyNav from './MyNav'
//import Consent from './Consent'



//import 'modern-normalize/modern-normalize.css'
//import './globalStyles.css'
import './boot.css'
import './froala.css'

//import './purpose.css'
import './myStyle.css'

export default ({ children, meta, title }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            socialMediaCard {
              image
            }
          }

        }
      `}
      render={data => {
        const { siteTitle, socialMediaCard } = data.settingsYaml || {};

        return (
          <Fragment>
            <Helmet
              htmlAttributes={{"lang": "fr"}}
              defaultTitle={siteTitle}
              titleTemplate={`%s`}
            >
              {title}
              <link href="https://ucarecdn.com" rel="preconnect" crossorigin />
              <link rel="dns-prefetch" href="https://ucarecdn.com" />
              {/* Add font link tags here */}
              {/* TODO: Import with Typeface instead */}
              <link href="https://fonts.googleapis.com/css?family=Montserrat:700|Nunito+Sans" rel="stylesheet" />
              {<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6801750087232009" crossorigin="anonymous"></script>}
              {/* <script type="text/javascript" src='concent.js'></script> */}
            </Helmet>
            {/* <Consent /> */}

            <Meta
              absoluteImageUrl={
                socialMediaCard &&
                socialMediaCard.image &&
                socialMediaCard.image
              }
              {...meta}
              {...data.settingsYaml}
            />

            {/* <Nav subNav={subNav} /> */}
            <MyNav />
            <Fragment>{children}</Fragment>

            <MyFooter />
          </Fragment>
        )
      }}
    />
  )
}
