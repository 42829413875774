import React, { Component } from 'react';
import { Link } from "gatsby"


class MyFooter extends Component {
  render() {
    return <footer className="fdb-block footer-large bg-gradient-primary">
  <div className="container">
    <div className="row align-items-top text-center text-md-left">
      <div className="col-12 col-sm-6 col-md-4 col-lg-4 text-sm-left">
        <h4 className="h3">Pseudo pour jeux</h4>
        <nav className="nav flex-column">
          <Link className="nav-link" to="/pseudo-fortnite/">Pseudo pour Fortnite</Link>
          <Link className="nav-link" to="/pseudo-world-of-warcraft/">Pseudo pour WoW</Link>
          <Link className="nav-link" to="/pseudo-minecraft/">Pseudo pour Minecraft</Link>
          <Link className="nav-link" to="/pseudo-runescape/">Pseudo pour RuneScape</Link>
        </nav>
      </div>

      <div className="col-12 col-sm-6 col-md-4 col-lg-4 mt-5 mt-sm-0 text-sm-left">
        <h4 className="h3">Pseudo pour réseaux sociaux</h4>
        <nav className="nav flex-column">
          <Link className="nav-link" to="/pseudo-instagram/">Pseudo pour Instagram</Link>
          <Link className="nav-link" to="/pseudo-discord/">Pseudo pour Discord</Link>
        </nav>
      </div>

      <div className="col-12 col-md-4 col-lg-4 text-md-left mt-5 mt-md-0">
        <h4 className="h3">Autres</h4>
        <nav className="nav flex-column">
          <Link className="nav-link" to="/contact/">Contact</Link>
        </nav>
      </div>

    </div>

    <div className="row mt-3 delimiter-top pt-4">
      <div className="col text-center">
        Pseudoly
      </div>
    </div>
  </div>
</footer>

  }
}

export default MyFooter
